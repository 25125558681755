<template>
  <div>
    <b-modal size="xl" class="modal-size" hide-footer v-model="modal_kardex_existence_movement" :title="product + ' - MOVIMIENTO DE KARDEX'">
      <b-row>
        <b-col md="12">
          <div class="table-responsive mt-3">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th rowspan="2" width="3%" class="text-center">#</th>
                  <th rowspan="2" width="20%" class="text-center">Almacen</th>
                  <th rowspan="2" width="10%" class="text-center">Modulo</th>
                  <th rowspan="2" width="7%" class="text-center">Fecha</th>
                  <th rowspan="2" width="12%" class="text-center">Comprobante</th>
                  <th colspan="3" width="16%" class="text-center">Entrada</th>
                  <th colspan="3" width="16%" class="text-center">Salida</th>
                  <th colspan="3" width="16%" class="text-center">Saldo</th>
                </tr>
                <tr>
                  <th class="text-center">Cant.</th>
                  <th class="text-center">P. Unit.</th>
                  <th class="text-center">P. Total</th>

                  <th class="text-center">Cant.</th>
                  <th class="text-center">P. Unit.</th>
                  <th class="text-center">P. Total</th>

                  <th class="text-center">Cant.</th>
                  <th class="text-center">P. Unit.</th>
                  <th class="text-center">P. Total</th>
                  
                </tr>
              </thead>
              <tbody v-for="(item, it) in kardex_movement" :key="it">
                <tr>
                  <td class="text-center">{{ it + 1 }}</td>
                  <td class="text-left"> {{ item.warehouse_name }}</td>
                  <td class="text-left"> {{ item.module }}</td>
                  <td class="text-center"> {{ item.broadcast_date }}</td>
                  <td class="text-center"> {{ item.invoice }}</td>

                  <td class="text-center bg-info" > {{ item.input }}</td>
                  <td class="text-center bg-info"> {{ item.input_unit_price }}</td>
                  <td class="text-center bg-info"> {{ item.input_total_price }}</td>

                  <td class="text-center bg-warning"> {{ item.output }}</td>
                  <td class="text-center bg-warning"> {{ item.output_unit_price }}</td>
                  <td class="text-center bg-warning"> {{ item.output_total_price }}</td>

                  <td class="text-right bg-success"> {{ item.balance }}</td>
                  <td class="text-right bg-success"> {{ item.balance_unit_price }}</td>
                  <td class="text-right bg-success"> {{ item.balance_total_price }}</td>

                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
        
   
      </b-row>
    </b-modal>
    <LoadingComponent :is-visible="isLoading"/>
    
  </div>
</template>

<style>


</style>

<script>

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import EventBus from "@/assets/js/EventBus";
var moment = require("moment");
import CodeToName from "@/assets/js/CodeToName";
import LoadingComponent from './../pages/Loading'

export default {
  name: "ModalClient",
    components:{
      LoadingComponent,
  },
  data() {
    return {
      module: 'KardexExistence',
      isLoading: false,
      modal_kardex_existence_movement: false,
      product: '',
      id_product:'',
      id_establishment:'',
      id_warehouse:'',
      voucher_of_payment:'',
      to:'',
      kardex_movement: [],
    };
  },
  created (){
  
  },
  mounted () {
    EventBus.$on('ModalKardexExistenceMovementShow', (data) => {
      //this.product = data.product;
      this.id_product = data.id_product;
      this.id_establishment = data.id_establishment;
      this.id_warehouse = data.id_warehouse;
      this.voucher_of_payment = data.voucher_of_payment;
      this.to = data.to;

      this.modal_kardex_existence_movement = true;
      this.ListKardexExistenceMovement();
    });
  },
  methods: {
    ListKardexExistenceMovement,
    CodeInvoice,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function CodeInvoice(code) {
  return CodeToName.CodeInvoice(code);
}

function ListKardexExistenceMovement() {
  let me = this;
  this.isLoading = true;
  let data = {
    id_product : this.id_product,
    id_establishment : this.id_establishment,
    id_warehouse : this.id_warehouse,
    voucher_of_payment : this.voucher_of_payment,
    to : this.to,
  }
  let url = this.url_base + "kardex/existence-by-product";
 
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { token: this.token, module: this.module,role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.kardex_movement = response.data.result;
        me.product = response.data.product.code+" - "+ response.data.product.name + (response.data.product.presentation.length == 0 ? "": " - ".response.data.product.presentation);
        me.isLoading = false;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
        me.isLoading = false;
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}


</script>
